export const filterStatusAndHasMetrics = (edges) => {
  return edges.reduce((acc, {node}) => {
    if(node.status === "published") {
      if(node.hasMetrics) {
        acc.push(node)
      }
    }
    return acc
  },[])
}

export const filterStatusAndHasMetricsTemplates = (edges) => {
  return edges.reduce((acc, {node}) => {
    if(node.status === "published") {
      if(node.instantMetricsCount > 0) {
        acc.push(node)
      }
    }
    return acc
  },[])
}