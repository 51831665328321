import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link as GatsbyLink  } from 'gatsby'
import Layout from '@organisms/layout'
import SEO from '@atoms/seo'
import Heading from '@particles/heading'
import Link from "@atoms/link"
import Icon from '@atoms/icons'
import Cards from '@molecules/cards'
import Pagination from '@atoms/pagination'
import Text from '@particles/text'
import styled from 'styled-components'
import { media } from '@breakpoints'
import Dropdown from "@molecules/dropdown"
import Icons from "@atoms/icons"
import SubNav from '@molecules/sub-nav'
import { filterStatusAndHasMetrics, filterStatusAndHasMetricsTemplates } from "/src/utils/graphql-helpers"
import Toggle from '@atoms/toggle'
import { isExpertContributed } from '@styles/helper'

const AllMetricPageStyle = styled(Layout)`
  ${media.phablet`

    .side-grid {
      grid-template-columns: 1fr;
    }

  `}

  ${media.desktop_small`

    .hide-filters {
      display: none;
    }
  `}

  #category_list {
    margin-left: -1rem;
  }
`

const AllMetricPage = ({
  data,
  pageContext: {
    currentPage,
    numPages,
    relativePath,
    categories,
    description,
    categoryName,
    // totalMetrics
  },
  location
}) => {
  const metrics = data.metrics.edges
  const services = filterStatusAndHasMetricsTemplates(data.services.edges)
  const roles = filterStatusAndHasMetrics(data.roles.edges)
  categories = [{data: "All metrics categories", url: "/all-metrics", suffix: false} , ...categories]
  const desc = description || 'Learn more about the metrics that matter the most to your business success.'
  
  let init = 0;
  for(let i = 0; i < categories.length; i++) {
    if(categories[i].data === categoryName) {
      init = i;
      break;
    }
  }

  const appendCurrentCategory = () => {
    if(categoryName) {
      return `&category=${categoryName}`
    } else {
      return ""
    }
  }
  

  return (
    <AllMetricPageStyle location={location} subnav>
      <SEO
        title={`${categoryName || 'All'} Metrics - ${currentPage}`}
        location={location}
        description={desc}
      />
      <SubNav>
        <Layout.Row justify={"space-between"} style={{flex: 1}}>
          <Layout.Row>
            <Dropdown 
              style={{marginRight: "1rem"}}
              title={"All metrics categories"} 
              init={init} 
              options={categories.map((v) => {return {to: v.url, label: v.data, suffix: v.suffix}})} 
              suffix={" Metrics"}
              links
            />
            <Dropdown
              style={{marginRight: "1rem"}}
              title={"Services"}
              className={"hide-filters"}
              multiple
              links
              options={services.map((node) => {
                return {to: `/all-metrics-filter/?service=${node.name}${appendCurrentCategory()}`, label: node.name}
              })}
              icon={<Icons size={1} name="sort" />}
            />
            <Dropdown
              style={{marginRight: "1rem"}}
              title={"Roles"}
              multiple
              className={"hide-filters"}
              links
              options={roles.map((node) => {
                return {to: `/all-metrics-filter/?role=${node.name}${appendCurrentCategory()}`, label: node.name}
              })}
              icon={<Icons size={1} name="sort" />}
            />
            <Dropdown title={"Sort"} 
              icon={<Icons name={"sort-list"} size={1}/>}
              init={0} 
              className={"hide-filters"}
              options={[
                {label: "Alphabetically (A-Z)", value: "alphabetical_asc", to: `/all-metrics-filter/?sort=0${appendCurrentCategory()}`}, 
                {label: "Alphabetically (Z-A)", value: "alphabetical_desc", to: `/all-metrics-filter/?sort=1${appendCurrentCategory()}`}, 
                {label: "Newly Added", value: "created", to: `/all-metrics-filter/?sort=2${appendCurrentCategory()}`},
                {label: "Last Updated", value: "updated", to: `/all-metrics-filter/?sort=3${appendCurrentCategory()}`}
              ]}
              links
            />
          </Layout.Row>
          <Layout.Row justify={"center"} className={"hide-filters"}>
            <GatsbyLink to={`/all-metrics-filter/?instant=true${appendCurrentCategory()}`} style={{textDecoration: "none"}}><Toggle label={"Instant Metrics"} icon={"metric"}/></GatsbyLink>
            <GatsbyLink to={`/all-metrics-filter/?benchmark=true${appendCurrentCategory()}`} style={{textDecoration: "none"}}><Toggle label={"Benchmark"} icon={"goal"} style={{margin: "0 1rem"}}/></GatsbyLink>
            <GatsbyLink to={`/all-metrics-filter/?expert=true${appendCurrentCategory()}`} style={{textDecoration: "none"}}><Toggle label={"Expert Contributed"} icon={"user"}/></GatsbyLink>
          </Layout.Row>
        </Layout.Row>
      </SubNav>
      <Heading center h1 margin="T8 B1">
        {categoryName ? `${categoryName} Metrics` : 'All Metrics'}
      </Heading>
      <Text center size={1.25} style={{ maxWidth: '550px', margin: '0 auto' }}>
        {desc}
      </Text>
      <Layout.Grid fixed={294} gap={1.5} margin="T4 L0">
        {metrics.map(({ node }, i) => {
          const hasInstant = node.instant_metrics?.length > 0 ? true : false 
          const hasBenchmark = node.benchmark ? true : false 
          const hasExpert = isExpertContributed(node.contributor)
          const hasIcon = hasInstant || hasBenchmark || hasExpert
          return (
            <Cards.Metric
              key={`${node.name}-${i}`}
              header={node.name}
              auto="true"
              hover="true"
              link={node.slug}
              footer={
                <Layout.Row style={{margin: "0 -0.25rem"}}>
                  {hasInstant && <Icon card name={"metric"}/>}
                  {hasBenchmark && <Icon card name={"goal"}/>}
                  {hasExpert && <Icon card name={"user"}/>}
                </Layout.Row>
              }
            >
              <Text lines={(hasIcon ? 4 : 5) - Math.floor((node.name.length / 24))}>
                {node.description.trim()}
              </Text>
            </Cards.Metric>
          )
        })}
      </Layout.Grid>
      {/* <Layout.Grid
        className="side-grid"
        margin="T4.375"
        gridTemplate="1.11fr 4.2fr"
        gap={2}
      >
        <List
          id="category_list"
          name="Categories"
          collapsed={isPhablet}
          sticky="80px"
        >
          <List.Item
            key="all-metrics"
            suffix={totalMetrics}
            active={categoryName === undefined}
            link="/all-metrics"
          >
            All
          </List.Item>
          {categories &&
            categories.map((v, i) => {
              return (
                <List.Item
                  key={`${v.data}-${i}`}
                  suffix={v.number}
                  active={v.data === categoryName}
                  link={v.url}
                >
                  {v.data}
                </List.Item>
              )
            })}
        </List>
        <div>
          
        </div>
      </Layout.Grid> */}
      <Layout.Center margin={"T2"}>
        <Pagination
          relativePath={relativePath}
          currentPage={currentPage}
          totalPages={numPages}
        />
      </Layout.Center>
      <Layout.Center margin={"T4"}>
        <Heading h4>Can&apos;t find what you are looking for?</Heading>
        <Link style={{fontSize: "1.2rem"}} to={"/contribute"} margin={"T1"}>Contribute a metric</Link>
      </Layout.Center>
    </AllMetricPageStyle>
  )
}
AllMetricPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.any,
  location: PropTypes.any
}
export default AllMetricPage

export const pageQuery = graphql`
  query allMetricQuery($skip: Int!, $limit: Int!, $filter: String) {
    metrics: allMetric(
      limit: $limit,
      skip: $skip,
      filter: {category: {elemMatch: {directus_id: {eq: $filter}, status: {eq: "published"}}}, status: {eq: "published"}}
      sort: {order: ASC, fields: name}
    ) {
      edges {
        node {
          alternate_name {
            name
          }
          category {
            name
            status
            description
          }
          name
          instant_metrics {
            id
          }
          benchmark
          description
          slug
          contributor {
            name
            status
          }
          role {
            id
          }
        }
      }
    }
    roles: allRoles(filter: {status: {eq: "published"}}) {
      edges {
        node {
          name
          status
          hasMetrics
          id
        }
      }
    }
    services: allServices(filter: {status: {eq: "published"}}) {
      edges {
        node {
          status
          name
          instantMetricsCount
        }
      }
    }
  }
`
